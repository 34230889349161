import {Box, Container, Stack, Typography} from '@mui/material'
import {Map} from 'immutable'
import {useDropzone} from 'react-dropzone'
import {useEffect, useState} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import File from './File'

import uploadIcon from '../../../dashboard/assets/programs/upload-icon.svg'
import Logger from '../../../lib/NewLogger'
import {
  sortFiles,
  fileValidator,
  removeDoubleFileName,
  processFilesFromDropzone
} from './formTools'

import './filesDropzoneArea.scss'


const FilesDropzoneArea = props => {
  const [files, setFiles] = useState([])

  const logEvent = event => {
    const item = props.itemResponse.get('header')

    Logger.log({
      name: event,
      payload: {
        actor: 'planholder',
        context: props.context,
        element_id: props.element.get('id'),
        everplan_id: props.itemResponse.get('everplan-id'),
        item,
        type: 'program',
        view_id: props.viewId,
        wildcard: item
      }
    })
  }

  const {getRootProps, getInputProps} = useDropzone({
    validator: fileValidator,
    multiple: true,
    onDrop: (acceptedFiles, fileRejections) => {
      props.setIsUploadingFiles(true)

      const processedFiles = processFilesFromDropzone({acceptedFiles, fileRejections})

      setFiles(prevFiles => removeDoubleFileName({
        newFiles: processedFiles,
        prevFiles
      }))

      acceptedFiles.forEach(() => logEvent('started_file_upload'))
    }
  })

  useEffect(() => {
    props.onChange(files.filter(file => !file.error))
    props.setIsUploadingFiles(files.some(file => ['uploaded', 'scanning'].includes(file.status)))
  }, [files, props.onChange, props.setIsUploadingFiles])

  const updateFile = updatedFile => {
    setFiles(prevFiles => prevFiles.map(file =>
      (file.name === updatedFile.name ? updatedFile : file)
    ))
  }

  const handleDelete = fileToDelete => {
    setFiles(files.filter(file => file.name !== fileToDelete.name))
  }

  return (
    <Box className='files-dropzone-area'>
      <label>{props.label}</label>
      <Container
        className={classnames('container', {
          'with-errors': files.some(file => file.error)
        })}>
        <Box {...getRootProps()} className='dropzone-box'>
          <input {...getInputProps()} />
          <img alt='upload-icon' className='upload-icon' src={uploadIcon} />
          <Typography className='body-text' variant='body1'>
            Select files to upload or drop your file here
          </Typography>
        </Box>
        {
          files.length > 0 && (
            <Stack className='files-list'>
              {
                sortFiles(files).map(file => (
                  <File
                    logEvent={logEvent}
                    key={file.tempId}
                    file={file}
                    userConfig={props.userConfig}
                    handleDelete={handleDelete}
                    updateFile={updateFile}
                  />
                ))
              }
            </Stack>
          )
        }
      </Container>
    </Box>
  )
}

FilesDropzoneArea.propTypes = {
  context: PropTypes.string,
  element: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map),
  label: PropTypes.string,
  onChange: PropTypes.func,
  setIsUploadingFiles: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  viewId: PropTypes.string
}

export default FilesDropzoneArea
