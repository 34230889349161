import {Map, List} from 'immutable'
import {useQuery} from '@apollo/client'
import {useState, useMemo} from 'react'
import PropTypes from 'prop-types'

import NewbieDashboardModule from './NewbieDashboardModule.jsx'
import Program from '../Program.jsx'

import {cleanGraphQLResponse} from '../../../../../lib/graphQLTools.js'
import {CMS} from '../../../../../graphql/queries/cms.js'
import {newbieProgramConfig, newbieProgramNextUpScreen} from '../../../../../lib/programTools.js'


const NewbieProgramContainer = props => {
  const [open, setOpen] = useState(false)
  const {data = {}} = useQuery(CMS)

  const hasWill = props.assessment.getIn(['data', 'will'])

  const program = useMemo(() => cleanGraphQLResponse(data.cms, 'newbieProgram', Map()), [data])
  const addedWill = useMemo(() => !props.itemResponses.find(itemResponse => itemResponse.get('item-id') === 'c2e9c420-e330-4b17-aa06-39ffa9e30b98').get('response-groups').isEmpty(), [props.itemResponses])
  const programConfig = useMemo(() => newbieProgramConfig({...props, program, hasWill, addedWill}), [props.userProgram, program, hasWill, addedWill])
  const upNextScreen = useMemo(() => newbieProgramNextUpScreen({programConfig, partner: props.partner, program}), [programConfig])

  if (!programConfig)
    return <div>loading</div>

  return (
    <div>
      <NewbieDashboardModule
        program={program}
        programConfig={programConfig}
        hasStartedProgram={!!props.userProgram}
        setOpen={setOpen}
      />
      {
        open && (
          <Program
            {...props}
            program={program}
            programConfig={programConfig}
            upNextScreen={upNextScreen}
            close={() => setOpen(false)}
            open={open}
          />
        )
      }
    </div>

  )
}

NewbieProgramContainer.propTypes = {
  assessment: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  partner: PropTypes.instanceOf(Map),
  userProgram: PropTypes.instanceOf(Map)
}

export default NewbieProgramContainer
