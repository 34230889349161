import {Box, Typography} from '@mui/material'
import {Map} from 'immutable'
import {useEffect} from 'react'
import PropTypes from 'prop-types'

import AnimatedScore from '../../../../shared_components/AnimatedScore'
import Button from '../../../../shared_components/mui_base_components/button/Button'

import {useScoreData} from '../../../../lib/hooks'

import './scoreScreen.scss'


const ScoreScreen = ({setCurrentSectionName, sectionConfig, userConfig, processingScoreEvent, logMixpanelProgramEvent}) => {
  const {score, refetch} = useScoreData(userConfig.get('id'))

  // Need to refetch onMount and in the unlikely event that the event has not finished processing before the
  // user gets here
  useEffect(() => {
    refetch()
  }, [processingScoreEvent])

  useEffect(() => {
    logMixpanelProgramEvent({name: 'view_program_screen', payload: {screen: `${sectionConfig.name}_score`}})
  }, [])

  return (
    <Box className='score-screen'>
      <Typography className='heading' variant='h3'>
      Congrats! You earned {sectionConfig.pointsEarned} points in this section.
      </Typography>
      <AnimatedScore referenceArcColor='#FFF' value={score.get('total')} />
      <Typography className='score-screen-text' variant='body1'>
        {sectionConfig.section.get('score-screen-text')}
      </Typography>
      <Button onClick={() => setCurrentSectionName('milestone')}>Continue</Button>
    </Box>
  )
}

ScoreScreen.propTypes = {
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  processingScoreEvent: PropTypes.bool,
  sectionConfig: PropTypes.shape({
    name: PropTypes.string,
    pointsEarned: PropTypes.number,
    section: PropTypes.instanceOf(Map)
  }),
  setCurrentSectionName: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default ScoreScreen
