import PropTypes from 'prop-types'
import {useRef, useState} from 'react'
import {fromJS} from 'immutable'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import LinkButton from '../../../../../shared_components/mui_base_components/link_button/LinkButton'
import Fields from '../../../../../shared_components/forms/v2/fields'
import Form from '../../../../../shared_components/forms/v2/Form'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'

import validators from '../../../../../shared_components/forms/v2/validators'

import './addDeputyStepOne.scss'

const RELATIONSHIPS = [ // Temporary stubbing of relationships until we get them from the DB. --BLR
  {label: 'Select a relationship', value: ''},
  {label: 'Spouse/Domestic Partner', value: 'Spouse/Domestic Partner'},
  {label: 'Child', value: 'Child'},
  {label: 'Parent', value: 'Parent'},
  {label: 'Sibling', value: 'Sibling'},
  {label: 'Friend', value: 'Friend'},
  {label: 'Advisor', value: 'Advisor'},
  {label: 'Attorney', value: 'Attorney'},
  {label: 'Caregiver/Sitter', value: 'Caregiver/Sitter'},
  {label: 'Other', value: 'Other'}
]

const AddDeputyStepOne = props => {
  const formRef = useRef()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState({})

  const basicDeputyInviteInfo = formData => ({
    email: formData.getIn(['email', 'value']),
    'first-name': formData.getIn(['name', 'value', 'first-name']),
    'last-name': formData.getIn(['name', 'value', 'last-name'])
  })

  const goToPermissions = isFirmUser => {
    setProcessing(false)

    const formData = formRef.current.onSubmit()

    if (formData) {
      props.nextStep({
        ...basicDeputyInviteInfo(formData),
        firmUser: isFirmUser,
        'deputy-relationship': formData.getIn(['deputy-relationship', 'value'])
      })
    }
  }


  const validateInvite = () => {
    const formData = formRef.current.onSubmit()

    if (formData) {
      setProcessing(true)

      const payload = {
        type: 'deputy-validations',
        attributes: basicDeputyInviteInfo(formData)
      }

      props.createResource(payload)
        .then(response => {
          setErrors({})
          goToPermissions(response.data.attributes['firm-user'])
        })
        .catch(apiErrors => {
          const emailErrors = apiErrors.response.data.errors.map(error => error.title)
          setErrors({email: emailErrors})
          setProcessing(false)
        })
    }
  }

  const clearServerErrors = () => {
    if (!processing || !$.isEmptyObject(errors))
      setErrors({})
  }


  return (
    <div className='add-deputy-step-one'>
      <ModalHeader
        heading='Who are you adding as your deputy?'
        subheading='It could be your spouse/partner, family member, or anyone
                    you trust. You have full control over what you share with
                    them.'
      />
      <Form onChange={clearServerErrors} ref={formRef} className='core' errors={errors}>
        <Fields.Name
          data={
            fromJS({
              id: 'name',
              name: 'name',
              value: {
                'first-name': props.deputy['first-name'],
                'last-name': props.deputy['last-name']
              }
            })
          }
          validator={validators.requiredNameFirstLastValidator}
        />
        <Fields.Email
          data={
            fromJS({
              id: 'email',
              legend: 'Email',
              name: 'email',
              value: props.deputy.email
            })
          }
          validator={validators.emailValidator}
        />
        <Fields.Select
          data={
            fromJS({
              id: 'deputy-relationship',
              items: RELATIONSHIPS,
              legend: 'Relationship',
              name: 'deputy relationship',
              value: props.deputy['deputy-relationship']
            })
          }
          validator={validators.required}
        />
      </Form>
      <ButtonGroup>
        <Button
          onClick={validateInvite}
          loading={processing}>
          Choose permissions
        </Button>
        {
          props.showSkipLinkButton &&
          <LinkButton loading={processing} onClick={props.handleSkip}>
            Skip
          </LinkButton>
        }
      </ButtonGroup>
    </div>
  )
}

AddDeputyStepOne.propTypes = {
  deputy: PropTypes.shape({
    'deputy-relationship': PropTypes.string,
    email: PropTypes.string,
    'first-name': PropTypes.string,
    'last-name': PropTypes.string
  }),
  createResource: PropTypes.func,
  handleSkip: PropTypes.func,
  nextStep: PropTypes.func,
  showSkipLinkButton: PropTypes.bool
}

export default AddDeputyStepOne
