import PropTypes from 'prop-types'
import {List} from 'immutable'
import {Avatar, Stack, Typography} from '@mui/material'

import colors from '../../../../styles/abstracts/colors.module.scss'

import './circleContents.scss'

const CircleContents = ({contents}) => {
  if (contents.size > 0) {
    const circle = (content, index) => (
      <Avatar
        className='circle'
        key={index}
        sx={{
          background: content.get('color'),
          zIndex: contents.size - index
        }}>
        <Typography
          className='circle-text'
          component='span'
          sx={{
            color: content.get('color') === '#FF7670' ? colors.headerText : colors.lightText
          }}
          variant='body2'>
          {content.get('text')}
        </Typography>
      </Avatar>
    )

    return (
      <Stack
        alignItems='center'
        className='circle-contents'
        direction={{mobileSm: 'column', tabletMd: 'row'}}
        justifyContent='center'>
        {contents.map((content, index) => circle(content, index))}
      </Stack>
    )
  } else {
    return null
  }
}

CircleContents.propTypes = {
  contents: PropTypes.instanceOf(List)
}

export default CircleContents
