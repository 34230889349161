import PropTypes from 'prop-types'

import {Modal, styled} from '@mui/material'

import breakpoints from '../../../dashboard/styles/abstracts/breakpoints.module.scss'
import CloseIcon from '../../../shared_components/core/svg_icons/icons/CloseIcon'

/* Override any of the following styles using the MuiModal-root class */

const StyledModalContent = styled('div')(
  ({theme}) => `
    background: ${theme.palette.primary.boxBackground};
    border-radius: 20px;
    color: ${theme.palette.primary.bodyText};
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    max-width: 900px;
    outline: 0;
    padding: 32px;
    text-align: center;
    width: 90%;
    @media (min-width: ${breakpoints.tabletMd}) {
      padding: 48px;
    }
  `
)

const StyledModal = ({onClose, open, children}) => (
  <Modal
    onClose={reason => (reason === 'backdropClick' ? null : onClose)}
    open={open}
    sx={{
      overflowX: 'hidden'
    }}>
    <StyledModalContent>
      <CloseIcon
        height='15'
        onClick={onClose}
        style={{alignSelf: 'flex-end', cursor: 'pointer', zIndex: '10'}}
        width='15'
      />
      {children}
    </StyledModalContent>
  </Modal>
)

StyledModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default StyledModal
