import PropTypes from 'prop-types'
import {Button, Typography} from '@mui/material'

import hideIcon from '../../shared_assets/v2/mask-icon.svg'
import showIcon from '../../shared_assets/v2/unmask-icon.svg'

import './maskTextButton.scss'

const MaskTextButton = ({showText, toggleButton}) => (
  <Button className='mask-text-button' onClick={toggleButton}>
    <img alt='' className='mask-icon' src={showText ? hideIcon : showIcon} />
    <Typography component='span'>
      {showText ? 'Hide' : 'Show'}
    </Typography>
  </Button>
)

MaskTextButton.propTypes = {
  showText: PropTypes.bool.isRequired,
  toggleButton: PropTypes.func.isRequired
}

export default MaskTextButton
