import {Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from '../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import MilestoneContainer from './milestone_screens/MilestoneContainer.jsx'
import Modal from '../../../../shared_components/mui_base_components/modal/Modal'
import SectionContainer from './SectionContainer.jsx'
import SharingContainer from './SharingContainer.jsx'
import UpNextContainer from './UpNextContainer.jsx'

import {USER_PROGRAMS} from '../../../../graphql/queries/userData.js'
import {CREATE_USER_PROGRAM, UPDATE_USER_PROGRAM} from '../../../../graphql/mutations/userData.js'
import {getActiveSectionName, buildUserProgramMutationVariables, allUserSectionsCompleted} from '../../../../lib/programTools.js'
import MixpanelLogger from '../../../../lib/MixpanelLogger.js'

const Program = props => {
  const [confirmClose, setConfirmClose] = useState(false)
  const [currentSectionName, setCurrentSectionName] = useState(() => getActiveSectionName(props.programConfig))
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [screenEventPayload, setScreenEventPayload] = useState({})

  const [updateUserProgram] = useMutation(UPDATE_USER_PROGRAM)
  const [createUserProgram] = useMutation(CREATE_USER_PROGRAM, {
    update: (cache, {data: userProgram}) => {
      const {userPrograms} = cache.readQuery({query: USER_PROGRAMS})

      cache.writeQuery({
        query: USER_PROGRAMS,
        data: {userPrograms: [...userPrograms, userProgram.createUserProgram]}
      })
    }
  })

  const logMixpanelProgramEvent = ({name, payload = {}}) => {
    setScreenEventPayload(payload)

    MixpanelLogger.track(name, {name: props.program.get('name'), ...payload})
  }

  const markProgramAsCompleted = () => {
    const {userProgram} = props

    updateUserProgram({
      variables: buildUserProgramMutationVariables({
        sections: userProgram.get('sections'),
        userProgram,
        status: 'completed'
      }),
      onCompleted: () => {
        logMixpanelProgramEvent({name: 'finish_program'})
      }
    })
  }

  useEffect(() => {
    props.setStopPollingScore(true)

    return () => {
      props.setStopPollingScore(false)
    }
  }, [])

  const logEventAndExit = () => {
    if (currentSectionName === 'up-next' || (!props.upNextScreen && allUserSectionsCompleted(props.programConfig)))
      return markProgramAsCompleted()

    logMixpanelProgramEvent({name: 'exit_program', payload: screenEventPayload})
    props.close()
  }

  const handleCloserClick = () => {
    confirmClose ? setOpenConfirmationModal(true) : logEventAndExit()
  }

  const handleFormChange = hasData => { setConfirmClose(hasData) }

  const sharedProps = {
    ...props,
    context: `${props.program.get('name')}_program`,
    logMixpanelProgramEvent,
    markProgramAsCompleted,
    sectionName: currentSectionName,
    onFormChange: handleFormChange,
    updateUserProgram,
    setCurrentSectionName
  }

  const renderSection = () => {
    switch (currentSectionName) {
      case 'sharing':
        return <SharingContainer {...sharedProps} />
      case 'milestone':
        return <MilestoneContainer {...sharedProps} createUserProgram={createUserProgram} />
      case 'up-next':
        return <UpNextContainer {...sharedProps} />
      default:
        return <SectionContainer {...sharedProps} />
    }
  }

  return (
    <>
      <Modal open={props.open} onClose={handleCloserClick}>
        {renderSection()}
      </Modal>
      <ConfirmationModal
        bodyText='You have unsaved data.'
        headingText='Exit this program?'
        onClose={() => setOpenConfirmationModal(false)}
        onConfirmation={logEventAndExit}
        open={openConfirmationModal}
      />
    </>
  )
}

Program.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  program: PropTypes.instanceOf(Map),
  programConfig: PropTypes.array,
  setStopPollingScore: PropTypes.func,
  upNextScreen: PropTypes.instanceOf(Map),
  userProgram: PropTypes.instanceOf(Map)
}

export default Program
