import classnames from 'classnames'
import {Map} from 'immutable'
import {useEffect, useMemo, useState} from 'react'
import {useMutation, useLazyQuery} from '@apollo/client'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'

import FileIcon from '../v2/form_helpers/FileIcon'
import {CREATE_UPLOAD_PRESIGNER, CREATE_UPLOAD} from '../../../graphql/mutations/userData'
import {UPLOAD} from '../../../graphql/queries/userData'
import {fileStatus} from './formTools'

import deleteFileUploadIcon from '../../../dashboard/assets/programs/delete-file-icon.svg'

import './file.scss'

const File = ({file, handleDelete, userConfig, updateFile, logEvent}) => {
  const [getUpload, {data: getUploadData, startPolling, stopPolling}] = useLazyQuery(UPLOAD)
  const [createUploadPresigner] = useMutation(CREATE_UPLOAD_PRESIGNER)
  const [createUpload, {data: createUploadData}] = useMutation(CREATE_UPLOAD)
  const [progress, setProgress] = useState(0)
  const [showProgressBar, setShowProgressBar] = useState(false)

  useEffect(() => {
    if (createUploadData && !file.id) {
      const upload = createUploadData.createUpload

      updateFile({...file, id: upload.id, status: upload.status})

      getUpload({
        variables: {id: upload.id},
        onCompleted: () => startPolling(1500)
      })
    }
  }, [createUploadData, file])

  useEffect(() => {
    const updatedFileStatus = getUploadData && getUploadData.upload.status

    if (!updatedFileStatus || file.status === updatedFileStatus) return

    if (['encrypted', 'virus_detected'].includes(updatedFileStatus))
      stopPolling()

    const newFileData = {...file, status: updatedFileStatus}

    if (updatedFileStatus === 'virus_detected')
      newFileData.error = 'A virus was detected with this file. Please check your email for details.'

    updateFile(newFileData)
  }, [getUploadData, file])

  useEffect(() => {
    switch (file.status) {
      case 'uploaded':
        setProgress(33)
        break
      case 'scanning':
        setProgress(66)
        break
      case 'encrypted':
      case 'rejected':
      case 'virus_detected':
        setProgress(100)
        break
      default:
    }
  }, [file.status])

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setShowProgressBar(false)
      }, 400)
    } else if (progress > 0) {
      setShowProgressBar(true)
    }
  }, [progress])

  const uploadPresignerToCloud = async ({presignedUrlData, fileData}) => {
    try {
      await axios.put(presignedUrlData.presignedUrl, fileData, {
        headers: {
          'Content-Type': file.filetype
        }
      })

      createUpload({
        variables: {
          input: {
            filename: file.name,
            filesize: file.filesize,
            everplanId: userConfig.get('everplan-id'),
            originalFileKey: presignedUrlData.fileObjectKey,
            filetype: file.filetype
          }
        }
      })
    } catch {
      updateFile({...file, error: 'Sorry, an error occurred.'})
    }
  }

  const getFileFromReaderAndUpload = presignedUrlData => {
    const reader = new FileReader()

    reader.onload = () => {
      uploadPresignerToCloud({presignedUrlData, fileData: reader.result})
    }

    reader.onerror = () => {
      updateFile({...file, error: 'Sorry, an error occurred.'})
    }

    reader.readAsArrayBuffer(file.file)
  }

  useEffect(() => {
    if (file.status === 'encrypted') {
      logEvent('upload_success')
    } else if (file.status === 'uploaded') {
      createUploadPresigner({
        variables: {filename: file.name},
        onCompleted: response => getFileFromReaderAndUpload(response.createUploadPresigner)
      })
    }
  }, [file.status])

  const status = useMemo(() => fileStatus(file), [file])

  return (
    <Stack alignItems='center' className='file' direction='row'>
      <FileIcon className='file-icon' fileName={file.name} />
      <div className='outer-container'>
        <div className='file-text-icon-button-container'>
          <div className='file-name-file-status-container'>
            <Typography
              className={classnames('file-name', {'with-error': file.error})}>
              {file.name}
            </Typography>
            {
              file.error &&
                <Typography className='error' color='error'>
                  {file.error}
                </Typography>
            }
            {
              status &&
              <Typography className='file-status' variant='body2'>
                {status}
              </Typography>
            }
          </div>
          {
            !file.error &&
            <IconButton
              className='icon-button'
              onClick={() => handleDelete(file)}>
              <img
                alt='delete-file-upload-icon'
                className='delete-file-upload-icon'
                src={deleteFileUploadIcon}
              />
            </IconButton>
          }
        </div>
        {
          !file.error && showProgressBar &&
          <LinearProgress
            className='file-upload-progress-bar'
            value={progress}
            variant='determinate'
          />
        }
      </div>
    </Stack>
  )
}

File.propTypes = {
  file: PropTypes.object,
  handleDelete: PropTypes.func,
  logEvent: PropTypes.func,
  updateFile: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}


export default File
