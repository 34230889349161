import PropTypes from 'prop-types'
import {Map} from 'immutable'

import Select from './Select'
import Date from './Date'
import Text from './Text'
import State from './State'
import Files from './Files'

const ElementField = props => {
  const sharedArguments = {
    label: props.element.get('field-prompt'),
    name: `${props.element.get('id')}`,
    placeholder: props.element.get('placeholder'),
    userConfig: props.userConfig
  }

  if (props.element.get('select-type')) {
    const options = props.element.get('mappings').map(mapping => mapping.set('label', mapping.get('value'))).toJS()

    return (
      <Select
        {...sharedArguments}
        defaultValue={options[0].value}
        options={options}
      />
    )
  }

  switch (props.element.get('type')) {
    case 'DATE':
      return <Date {...sharedArguments} id={props.element.get('id')} />
    case 'TEXT':
      return (
        <Text {...sharedArguments} maskText={props.element.get('mask-text')} />
      )
    case 'STATE':
      return <State {...sharedArguments} />
    case 'FILE':
      return <Files {...props} {...sharedArguments} />
    default:
      return null
  }
}

ElementField.propTypes = {
  element: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map),
  setIsUploadingFiles: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  viewId: PropTypes.string
}

export default ElementField
