import {Map} from 'immutable'
import {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'

import AnimatedProgressBar from '../../../../shared_components/AnimatedProgressBar.jsx'
import ContentScreen from './content_screen/ContentScreen.jsx'
import ItemScreenContainer from './item_screens/ItemScreenContainer.jsx'
import ScoreScreen from './ScoreScreen.jsx'

import {
  buildUserProgramMutationVariables,
  getCompletedScreensCount,
  getCurrentSectionScreen,
  getTotalScreensCount,
  mergeUpdatedSectionIntoUserSections,
  updateUserSection
} from '../../../../lib/programTools.js'
import {useScoreLedgerEventLogger} from '../../../../lib/ScoreLedgerEventLogger.js'

import './sectionContainer.scss'

const SectionContainer = props => {
  const {logWithoutNotification, processingScoreEvent} = useScoreLedgerEventLogger(props.userConfig)

  const sectionConfig = useMemo(() => (
    props.programConfig.find(config => config.name === props.sectionName)
  ), [props.programConfig, props.sectionName])

  const screens = sectionConfig.section.get('screens')

  const [currentScreen, setCurrentScreen] = useState(() => getCurrentSectionScreen({screens, userSection: sectionConfig.userSection}))
  const [completedScreensCount, setCompletedScreensCount] = useState(() => getCompletedScreensCount(currentScreen, screens))
  const [processing, setProcessing] = useState(false)
  const [completedPercentage, setCompletedPercentage] = useState(0)

  useEffect(() => {
    setCompletedPercentage(
      Math.round(completedScreensCount / getTotalScreensCount(screens) * 100)
    )
  }, [completedScreensCount, screens])

  const updateCompletedScreensCount = () => {
    if (currentScreen === 'score')
      setCompletedScreensCount(1)
    else if (completedScreensCount <= getTotalScreensCount(screens))
      setCompletedScreensCount(completedScreensCount + 1)
  }

  const updateUserProgramSection = updatedData => {
    const nextScreenNumber = currentScreen.get('next-screen')
    const nextScreen = nextScreenNumber === 0 ? 'score' : screens.find(screen => screen.get('sort-order') === nextScreenNumber)
    const updatedSection = updateUserSection({...updatedData, nextScreenNumber, userSection: sectionConfig.userSection})

    if (!updatedSection.equals(sectionConfig.userSection)) {
      setProcessing(true)

      const {userProgram} = props

      props.updateUserProgram({
        variables: buildUserProgramMutationVariables({
          sections: mergeUpdatedSectionIntoUserSections({userProgram, updatedSection}),
          userProgram: props.userProgram
        }),
        onCompleted: () => {
          const status = updatedSection.get('status')

          // If the section-type is vault then there is no need to log the scoring event like below,
          // since that is done in a different way for those sections.
          if (['active', 'skipped'].includes(status) || sectionConfig.section.get('section-type') === 'vault') {
            setProcessing(false)
            status === 'skipped' ? props.setCurrentSectionName('milestone') : setCurrentScreen(nextScreen)
          }
        }
      })
    } else {
      setCurrentScreen(nextScreen)
    }

    updateCompletedScreensCount()
  }

  useEffect(() => {
    const {userSection, section} = sectionConfig

    if (section.get('section-type') === 'content' && userSection.get('status') === 'completed')
      return logWithoutNotification.completedProgram({name: section.get('name')})
  }, [sectionConfig])

  useEffect(() => {
    // processingScoreEvent will only change to false if the an event is fired and the processing is done
    if (!processingScoreEvent) {
      setProcessing(false)
      setCurrentScreen('score')
    }
  }, [processingScoreEvent])


  if (currentScreen === 'score')
    return <ScoreScreen {...props} sectionConfig={sectionConfig} processingScoreEvent={processingScoreEvent} />

  return (
    <div className='section-container'>
      <AnimatedProgressBar
        completed={completedPercentage}
        show={!currentScreen.get('hide-progress-bar')}
      />
      {
        currentScreen.get('screen-type') === 'content' ?
          <ContentScreen
            logMixpanelProgramEvent={props.logMixpanelProgramEvent}
            handleNext={updateUserProgramSection}
            processing={processing}
            screen={currentScreen}
          /> :
          <ItemScreenContainer
            {...props}
            processing={processing}
            setProcessing={setProcessing}
            handleNext={updateUserProgramSection}
            screen={currentScreen}
            userSection={sectionConfig.userSection}
            updateCompletedScreensCount={updateCompletedScreensCount}
          />
      }
    </div>
  )
}

SectionContainer.propTypes = {
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  program: PropTypes.instanceOf(Map),
  programConfig: PropTypes.array,
  sectionName: PropTypes.string,
  setCurrentSectionName: PropTypes.func,
  updateUserProgram: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  userProgram: PropTypes.instanceOf(Map)
}

export default SectionContainer
