
import {Map} from 'immutable'
import {useState, useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'

import AddDeputyController from '../corp_max_deputies/add/AddDeputyController'
import ContentScreen from './content_screen/ContentScreen'
import ScoreScreen from './ScoreScreen'

import {hasDeputyWithDeferredPermissions} from '../../../../lib/deputyPermissionTools'
import {mergeUpdatedSectionIntoUserSections, buildUserProgramMutationVariables} from '../../../../lib/programTools'
import {useDeputyData} from '../../../../lib/hooks'
import {useScoreLedgerEventLogger} from '../../../../lib/ScoreLedgerEventLogger'
import Logger from '../../../../lib/NewLogger'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

import './sharingContainer.scss'

const SharingContainer = props => {
  const {logWithoutNotification, processingScoreEvent} = useScoreLedgerEventLogger(props.userConfig)
  const {deputies} = useDeputyData(props.userConfig.get('everplan-id'))

  const hasDeputyWithDeferredItems = useMemo(() => hasDeputyWithDeferredPermissions(deputies), [deputies])
  const sectionConfig = useMemo(() => props.programConfig.find(config => config.name === 'sharing'), [props.programConfig])

  const [currentScreen, setCurrentScreen] = useState(() => sectionConfig.section.getIn(['screens', 0]))
  const [deputy, setDeputy] = useState({
    'first-name': null,
    'last-name': null,
    email: null,
    inviteText: ''
  })

  const handleNext = () => {
    const nextScreenNumber = currentScreen.get('next-screen')

    if (nextScreenNumber === 0) {
      MixpanelLogger.track('click_add_deputy', {context: props.context, deputy_count: deputies.size})
      Logger.log({name: 'clicked_add_new_deputy', payload: {context: props.context}})
      props.logMixpanelProgramEvent({name: 'view_program_screen', payload: {screen: 'add_deputy'}})

      setCurrentScreen('add-deputy')
    } else {
      setCurrentScreen(sectionConfig.section.get('screens').find(screen => screen.get('sort-order') === nextScreenNumber))
    }
  }

  const updateUserSectionStatus = status => {
    props.onFormChange(false)

    if (status === 'completed')
      logWithoutNotification.addedDeputyFromPrompt()

    const {userProgram} = props

    props.updateUserProgram({
      variables: buildUserProgramMutationVariables({
        userProgram,
        sections: mergeUpdatedSectionIntoUserSections({
          updatedSection: sectionConfig.userSection.set('status', status),
          userProgram
        })
      }),
      onCompleted: () => {
        if (status === 'skipped') {
          props.logMixpanelProgramEvent({name: 'skip_program_screen', payload: {screen: 'add_deputy'}})
          props.setCurrentSectionName('milestone')
        } else {
          setCurrentScreen('score')
        }
      }
    })
  }

  useEffect(() => {
    props.onFormChange(Object.values(deputy).some(data => data))
  }, [deputy])

  switch (currentScreen) {
    case 'add-deputy':
      return (
        <div className='forms-playground sharing-container'>
          <AddDeputyController
            {...props}
            deputies={deputies}
            deputy={deputy}
            handleSkip={() => updateUserSectionStatus('skipped')}
            handleSubmitCompleted={() => updateUserSectionStatus('completed')}
            hasDeputyWithDeferredItems={hasDeputyWithDeferredItems}
            setDeputy={setDeputy}
            showSkipLinkButton
          />
        </div>
      )
    case 'score':
      return <ScoreScreen {...props} sectionConfig={sectionConfig} processingScoreEvent={processingScoreEvent} />
    default:
      return <ContentScreen {...props} handleNext={handleNext} screen={currentScreen} />
  }
}

SharingContainer.propTypes = {
  context: PropTypes.string,
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
  programConfig: PropTypes.array,
  sectionName: PropTypes.string,
  setCurrentSectionName: PropTypes.func,
  updateUserProgram: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  userProgram: PropTypes.instanceOf(Map)
}

export default SharingContainer
