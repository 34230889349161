import moment from 'moment'
import {fromJS} from 'immutable'
import _ from 'lodash'
import {v4 as uuidv4} from 'uuid'

import {fileExtension} from '../v2/lib/tools'
import fileWhitelist from '../../../lib/file_whitelist'

export const VALID_DATE_FORMATS = ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-MM-D', 'YYYY-M-DD']

export const validateDateInput = dateFieldValues => {
  const date = `${dateFieldValues.year || '2000'}-${dateFieldValues.month || '01'}-${dateFieldValues.day || '01'}`

  return moment(
    date,
    VALID_DATE_FORMATS,
    true // Enables strict mode to restrict formats to `VALID_DATE_FORMATS`
  ).format('YYYY-MM-DD')
}

export const isEmptyFormValue = data => {
  if (!data || !_.has(data, 'value')) return true

  const value = data.value

  if (_.isUndefined(value)) return true
  if (_.isString(value) && !value) return true
  if (_.isArray(value) && value.length === 0) return true
  if (_.isObject(value))
    return _.every(value, attr => !attr)


  return false
}

export const allFormValuesEmpty = formData => Object.values(formData).every(data => isEmptyFormValue(data))

export const formatElementResponsesFromForm = formData => (
  fromJS(Object.entries(formData).reduce((collection, [key, value]) => {
    collection.push({...value, 'element-id': key})

    return collection
  }, []))
)


export const fileValidator = file => {
  // This only happens when the user is hovering over the dropArea but has not dropped the file
  if (!file.name) return

  if (file.size > 110000000) {
    return {
      code: 'file-too-large', message: "Sorry, we can't support a file size of more than 110 MB."
    }
  }

  const extension = fileExtension(file.name)

  if (!extension || !fileWhitelist.includes(extension)) {
    return {
      code: 'extension-error',
      message: 'Sorry, this file format is not supported.'
    }
  }
}

export const removeDoubleFileName = ({newFiles, prevFiles}) => (
  // Convert the map back into an array
  Object.values(
    prevFiles.concat(newFiles).reduce((collection, file) => {
      // Goes through each object in the array and places it into the collection with the name as the key.
      // If there is a file already with the same name it overrides it with the new file (which would be the most recent one)
      collection[file.name] = file
      return collection
    }, {})
  )
)


export const sortFiles = files => (
  files.sort((fileA, fileB) => {
    // If 'fileA' has no error and 'fileB' has an error, 'fileA' should come first
    if (!fileA.error && fileB.error) return -1
    // If 'fileA' has an error and 'fileB' has no error, 'fileB' should come first
    if (fileA.error && !fileB.error) return 1
    // If both or neither have errors, keep their original order
    return 0
  })
)

export const fileStatus = file => {
  if (['uploaded', 'scanning'].includes(file.status))
    return 'Uploading'

  if (file.status === 'encrypting')
    return 'Encrypting'
}

export const processFilesFromDropzone = ({acceptedFiles, fileRejections}) => ([
  ...acceptedFiles.map(file => ({
    file,
    tempId: uuidv4(),
    name: file.name,
    filetype: file.type,
    filesize: file.size,
    status: 'uploaded'
  })),
  ...fileRejections.map(rejection => ({
    tempId: uuidv4(),
    error: rejection.errors[0].message,
    name: rejection.file.name,
    filetype: rejection.file.type,
    filesize: rejection.file.size,
    status: 'rejected'
  }))
])
