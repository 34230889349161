import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import AddDeputyController from './AddDeputyController'
import CancelDeputy from './CancelDeputy'
import Closer from '../../../../../shared_components/core/closer/Closer'
import DeputyAddedConfirmation from './DeputyAddedConfirmation'
import Modals from '../../../../../shared_components/core/modals/Modals'

import {deputizableDomainEmails} from '../../../../../lib/emailTools'
import {findRawResourceByAttributeId, findRawResourceById, findResourceById} from '../../../../../lib/plan_data/userData'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'

import './addDeputyModal.scss'

const AddDeputyModal = props => {
  if (props.showModal) {
    const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
    const [showDeputyAddedConfirmation, setShowDeputyAddedConfirmation] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const contact = findResourceById({resourceList: props.newContacts, id: props.params?.contactId})

    const [deputy, setDeputy] = useState({
      'first-name': contact.get('first-name'),
      'last-name': contact.get('last-name'),
      email: deputizableDomainEmails({contact, organization: props.organization}).first(),
      inviteText: ''
    })

    const toggleWarning = () => { setShowWarning(!showWarning) }

    const modalCloser = () => <Closer closer={deputy['first-name'] ? toggleWarning : props.exit} />

    const warningModal = () => {
      if (showWarning)
        return <CancelDeputy hideWarning={toggleWarning} exit={props.exit} />
    }

    const handleSubmitCompleted = () => {
      setShowDeputyAddedConfirmation(true)
      props.context === 'dashboard' ? logWithNotification.addedDeputyFromPrompt() : logWithNotification.addedDeputy()
    }


    if (showDeputyAddedConfirmation)
      return <DeputyAddedConfirmation firstName={deputy['first-name']} exit={props.exit} context={props.context} />

    return (
      <div className='forms-playground add-deputy-modal'>
        <Modals.ButtonPopUpModalLarge showModalOnMount closerComponent={modalCloser}>
          <AddDeputyController
            {...props}
            deputy={deputy}
            setDeputy={setDeputy}
            handleSubmitCompleted={handleSubmitCompleted}
          />
        </Modals.ButtonPopUpModalLarge>
        {warningModal()}
      </div>
    )
  } else {
    return null
  }
}

AddDeputyModal.defaultProps = {
  // This component is both a route component and also can be triggered from a button, and therefore
  // when it is triggered from a route it needs to default to show
  showModal: true
}


AddDeputyModal.propTypes = {
  context: PropTypes.string.isRequired,
  exit: PropTypes.func,
  newContacts: PropTypes.instanceOf(List),
  organization: PropTypes.instanceOf(Map),
  params: PropTypes.shape({contactId: PropTypes.string}),
  showModal: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = (state, {userConfig}) => {
  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id') || firmOwnership.get('ownable-id')
  })

  const organization = findRawResourceById({
    rawResource: state.api.organizations,
    id: firm.get('organization-id')
  })

  return {
    organization
  }
}


export default connect(mapStateToProps)(AddDeputyModal)
