import {Button, styled} from '@mui/material'

const StyledButton = styled(Button)(({theme, color}) => ({
  height: '42px',
  padding: '8px 32px',
  '&:hover': {
    backgroundColor: theme.palette[color].light
  },
  '&:disabled': {
    backgroundColor: theme.palette[color].disabled
  }

}))

export default StyledButton
