import PropTypes from 'prop-types'

import {animated, useSpring} from '@react-spring/web'

import './progressBar.scss'

const AnimatedProgressBar = ({completed, show}) => {
  const width = useSpring({
    from: {width: '0%'},
    to: {width: `${Math.min(completed, 100)}%`}
  })

  return (
    show ?
      <div className='bar'>
        <animated.div className='progress' style={width} />
      </div> :
      null
  )
}

AnimatedProgressBar.defaultProps = {
  show: true
}

AnimatedProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  show: PropTypes.bool
}

export default AnimatedProgressBar
