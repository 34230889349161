import {gql} from '@apollo/client'

import {CONTACT_FRAGMENT, UPLOAD_FRAGMENT, USER_PROGRAM_FRAGMENT} from '../fragments/userData'

export const CREATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation createContact($input: ContactInput!, $everplanId: Int!) {
    createContact(input: $input, everplanId: $everplanId) {
      ...ContactFragment
    }
  }
`
export const UPDATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation updateContact($input: ContactInput!, $everplanId: Int!) {
    updateContact(input: $input, everplanId: $everplanId) {
      ...ContactFragment
    }
  }
`

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: String!) {
    deleteContact(id: $id) {
      message
    }
  }
`

export const CREATE_ASSESSMENT = gql`
  mutation createScoreAssessment($input: CreateScoreAssessmentInput!) {
    createScoreAssessment(input: $input) {
      body
      heading
      total
    }
  }
`

export const CREATE_SCORE_LEDGER_EVENT = gql`
  mutation createScoreLedgerEvent($input: CreateScoreLedgerEventInput!) {
    createScoreLedgerEvent(input: $input) {
      id
      feedbackMessage
    }
  }
`

export const CREATE_UPLOAD = gql`
  ${UPLOAD_FRAGMENT}
  mutation createUpload($input: NewUploadInput!) {
    createUpload(input: $input) {
     ...NewUploadFragment
    }
  }
`

export const CREATE_UPLOAD_PRESIGNER = gql`
  mutation createUploadPresigner($filename: String!) {
    createUploadPresigner(filename: $filename) {
      id
      fileObjectKey
      presignedUrl
      filename
    }
  }
`

export const CREATE_USER_PROGRAM = gql`
  ${USER_PROGRAM_FRAGMENT}
  mutation createUserProgram($input: CreateUserProgramInput!) {
    createUserProgram(input: $input) {
      ...UserProgramFragment
    }
  }
`
export const UPDATE_USER_PROGRAM = gql`
  ${USER_PROGRAM_FRAGMENT}
  mutation updateUserProgram($input: UpdateUserProgramInput!) {
    updateUserProgram(input: $input) {
      ...UserProgramFragment
    }
  }
`
