import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import {Map} from 'immutable'

import Button from '../../../../../shared_components/mui_base_components/button/Button.jsx'
import MilestoneSections from './MilestoneSections'

import './milestoneScreen.scss'

const MilestoneScreen = ({handleClick, handleMount, programConfig, screen, userProgram, logMixpanelProgramEvent}) => {
  useEffect(() => {
    handleMount()

    let screenName = screen.get('milestone-screen-type')

    if (userProgram)
      screenName = `${programConfig[userProgram.get('sections').size - 1].name}_done`

    logMixpanelProgramEvent({name: 'view_program_screen', payload: {screen: screenName}})
  }, [])

  return (
    <Box className='milestone-screen'>
      <Typography className='heading' variant='h3'>
        {screen.get('heading')}
      </Typography>
      <Typography className='subheading'>
        {screen.get('sub-heading')}
      </Typography>
      <MilestoneSections programConfig={programConfig} />
      <Button onClick={handleClick}>
        {screen.get('button-text')}
      </Button>
    </Box>
  )
}

MilestoneScreen.defaultProps = {
  handleMount: () => {}
}

MilestoneScreen.propTypes = {
  handleClick: PropTypes.func,
  handleMount: PropTypes.func,
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  programConfig: PropTypes.array,
  screen: PropTypes.object,
  userProgram: PropTypes.instanceOf(Map)
}

export default MilestoneScreen
