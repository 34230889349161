import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useFormContext, Controller} from 'react-hook-form'
import {MenuItem} from '@mui/material'

import TextInput from './TextInput'

const Select = ({defaultValue, name, label, options, placeholder}) => {
  const {control, setValue} = useFormContext()

  useEffect(() => {
    setValue(`${name}.value`, defaultValue)
  }, [defaultValue])

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      defaultValue={defaultValue}
      render={({field}) => (
        <TextInput
          {...field}
          select
          label={label}
          placeholder={placeholder}>
          {
            options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          }
        </TextInput>
      )}
    />
  )
}

Select.defaultProps = {
  defaultValue: ''
}

Select.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string
}

export default Select
