import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {Box, Card, Typography} from '@mui/material'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import DashboardHeading from '../../../../../shared_components/mui_base_components/dashboard_heading/DashboardHeading'

import newbiePathImage from '../../../../assets/programs/road.svg'
import newbieProgramImage from '../../../../assets/programs/newbie-program-image.svg'

import {maxPossibleProgramPoints} from '../../../../../lib/programTools'

import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './newbieDashboardModule.scss'

const NewbieDashboardModule = ({hasStartedProgram, programConfig, setOpen}) => {
  useEffect(() => {
    MixpanelLogger.track('view_program_hero', {name: 'newbie'})
  }, [])

  const bodyText = hasStartedProgram ?
    "You're making great progress on our intro program, keep the momentum going and finish it out." :
    "Here's an intro program with super important things you can do right away. Follow along and watch your score go up."
  const buttonText = hasStartedProgram ? 'Continue' : 'Get started'
  const headingText = hasStartedProgram ? 'Keep going' : 'Take the first step'
  const possiblePoints = maxPossibleProgramPoints(programConfig)

  return (
    <div className='newbie-dashboard-module'>
      <DashboardHeading>Intro program for you</DashboardHeading>
      <Box component='section'>
        <Card>
          <Typography variant='body2'>
            {`Earn up to ${possiblePoints} points`}
          </Typography>
          <Typography variant='h6'>
            {headingText}
          </Typography>
          <Typography>
            {bodyText}
          </Typography>
          <Button onClick={() => setOpen(true)}>{buttonText}</Button>
        </Card>
        <img alt='' className='path' src={newbiePathImage} />
        <img alt='' className='full' src={newbieProgramImage} />
      </Box>
    </div>
  )
}

NewbieDashboardModule.propTypes = {
  hasStartedProgram: PropTypes.bool,
  programConfig: PropTypes.array,
  setOpen: PropTypes.func
}

export default NewbieDashboardModule
