import PropTypes from 'prop-types'
import {Button} from '@mui/material'

import StyledLinkText from './StyledLinkText'

const LinkButton = ({children, disabled, loading, onClick}) => (
  <Button
    className='link-button'
    disabled={disabled || loading}
    onClick={onClick}
    variant='text'>
    <StyledLinkText component='span'>{children}</StyledLinkText>
  </Button>
)

LinkButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default LinkButton
