import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Avatar, Box, Typography, Stack} from '@mui/material'

import {milestoneStatusText, programImages} from '../../../../../lib/programTools'

import checkMark from '../../../../../dashboard/assets/programs/check-mark.svg'

import './milestoneSections.scss'

const MilestoneSections = ({programConfig}) => (
  <Stack
    className='milestone-sections'
    direction={{mobileSm: 'column', tabletMd: 'row'}}
    spacing={9}
    useFlexGap>
    {
      programConfig.map((config, index) => {
        const completed = config.userSection.get('status') === 'completed'
        const milestoneConfig = config.section.get('milestone-config')

        return (
          <Box className='milestone-section' key={config.name}>
            <Stack className='milestone-section-points-earned-container' alignItems='center' direction='row'>
              {
                completed && <img alt='' className='milestone-section-completed-check-mark' src={checkMark} />
              }
              <Typography className='milestone-section-points-earned-text' variant='body1'>
                {milestoneStatusText(config)}
              </Typography>
            </Stack>
            <Box className={classnames('icon-container', {completed})}>
              <img alt='' src={programImages[milestoneConfig.get('image')]} />
            </Box>
            <Avatar className={classnames('order-number', {completed})}>
              <Typography variant='body1'>{index + 1}</Typography>
            </Avatar>
            <Typography variant='h6'>
              {milestoneConfig.get('heading')}
            </Typography>
            <Typography className='milestone-section-subheading' variant='body1'>
              {milestoneConfig.get('sub-heading')}
            </Typography>
          </Box>
        )
      })
    }
  </Stack>
)

MilestoneSections.propTypes = {
  programConfig: PropTypes.array
}

export default MilestoneSections
