import {Map} from 'immutable'
import {useEffect, useState, useMemo} from 'react'
import PropTypes from 'prop-types'

import ItemOptionsScreen from './ItemOptionsScreen'
import ItemFormScreen from './ItemFormScreen'

import {userItemOptions, getItemFormScreen} from '../../../../../lib/programTools'
import {findResourceById} from '../../../../../lib/plan_data/userData'
import {usePrevious} from '../../../../../lib/hooks'

import './itemScreenContainer.scss'

const ItemScreenContainer = props => {
  const [itemFormScreen, setItemFormScreen] = useState(() => getItemFormScreen(props.screen))

  const prevScreen = usePrevious(props.screen)

  const itemOptions = useMemo(() => (
    userItemOptions({screen: props.screen, userSection: props.userSection})
  ), [props.userSection, props.screen])


  const handleOptionSelected = formData => {
    const screen = findResourceById({resourceList: props.screen.get('item-options'), id: formData[props.screen.get('id')].value})

    setItemFormScreen(screen)
    props.updateCompletedScreensCount()
  }

  useEffect(() => {
    if (!prevScreen.equals(props.screen))
      setItemFormScreen()
  }, [props.screen])

  return (
    <div className='item-screen-container'>
      {
        itemFormScreen ?
          <ItemFormScreen {...props} screen={itemFormScreen} /> :
          <ItemOptionsScreen
            {...props}
            itemOptions={itemOptions}
            handleOptionSelected={handleOptionSelected}
          />
      }
    </div>
  )
}

ItemScreenContainer.propTypes = {
  userSection: PropTypes.instanceOf(Map),
  screen: PropTypes.instanceOf(Map),
  updateCompletedScreensCount: PropTypes.func
}


export default ItemScreenContainer
