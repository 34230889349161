import PropTypes from 'prop-types'
import {useState} from 'react'
import {useFormContext, Controller} from 'react-hook-form'
import {InputAdornment} from '@mui/material'

import MaskTextButton from '../MaskTextButton'
import TextInput from './TextInput'

const Text = ({name, label, maskText, placeholder}) => {
  const [showText, setShowText] = useState(!maskText)

  const {control} = useFormContext()

  const toggleMaskTextButton = () => setShowText(!showText)

  const inputProps = {
    endAdornment: <InputAdornment position='end'>
      <MaskTextButton
        showText={showText}
        toggleButton={toggleMaskTextButton}
      />
    </InputAdornment>
  }

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      defaultValue=''
      render={({field}) => (
        <TextInput
          {...field}
          autoComplete='off'
          helperText={placeholder}
          InputProps={maskText ? inputProps : null}
          label={label}
          type={showText ? 'text' : 'password'}
        />
      )}
    />
  )
}

Text.propTypes = {
  label: PropTypes.string,
  maskText: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}

export default Text
