import {Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import ContentScreen from './content_screen/ContentScreen'

import {useScoreLedgerEventLogger} from '../../../../lib/ScoreLedgerEventLogger'
import Logger from '../../../../lib/NewLogger'

const UpNextContainer = props => {
  const [processing, setProcessing] = useState(false)
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)

  const exitProgram = () => {
    setProcessing(true)
    props.markProgramAsCompleted()
  }

  const onNext = () => {
    exitProgram()

    const url = props.upNextScreen.get('call-to-action-url')

    if (url) {
      const item = props.upNextScreen.get('item-header')

      logWithNotification.clickedActionItemVendor({item})

      props.logMixpanelProgramEvent({
        name: 'click_program_partner_handoff',
        payload: {screen: props.upNextScreen.get('event-name')}
      })

      Logger.log({
        name: 'continued_to_gap_filler_vendor',
        payload: {
          context: props.context,
          item,
          vendor_url: url,
          wildcard: item
        }
      })

      // Suppress the actual tab opening in tests
      if (!__TEST__)
        window.open(url, '_blank')
    }
  }

  return (
    <ContentScreen
      eventName='view_program_partner_handoff'
      handleLinkClick={exitProgram}
      handleNext={onNext}
      logMixpanelProgramEvent={props.logMixpanelProgramEvent}
      processing={processing}
      screen={props.upNextScreen}
    />
  )
}

UpNextContainer.propTypes = {
  context: PropTypes.string.isRequired,
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  markProgramAsCompleted: PropTypes.func,
  upNextScreen: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default UpNextContainer
