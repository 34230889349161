import PropTypes from 'prop-types'
import {styled, Typography} from '@mui/material'

import colors from '../../../dashboard/styles/abstracts/colors.module.scss'

const StyledButtonText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'loading'})(({loading}) => ({
  color: colors.buttonTextLight,
  fontSize: '0.9375rem',
  fontWeight: '400',
  letterSpacing: '0.4px',
  lineHeight: '160%',
  visibility: loading ? 'hidden' : 'visible'
}))

StyledButtonText.propTypes = {
  loading: PropTypes.bool
}

export default StyledButtonText
