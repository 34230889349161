import {List} from 'immutable'
import PropTypes from 'prop-types'

import DashboardHeading from '../../../../../shared_components/mui_base_components/dashboard_heading/DashboardHeading'
import ItemWithResponsesBox from './ItemWithResponsesBox'

import {itemResponsesWithResponseGroupMap} from '../../../../../lib/plan_data/itemResponsesHelper'

const ItemsWithResponses = props => {
  const itemResponses = itemResponsesWithResponseGroupMap(props.categoryItemResponses)

  if (itemResponses.isEmpty()) {
    return null
  } else {
    return (
      <div className='items-with-responses'>
        <DashboardHeading>In your Everplan</DashboardHeading>
        {
          itemResponses
            .map(itemResponse =>
              (
                <ItemWithResponsesBox
                  {...props}
                  itemResponse={itemResponse}
                  key={itemResponse.get('id')}
                />
              )
            )
        }
      </div>
    )
  }
}

ItemsWithResponses.propTypes = {
  categoryItemResponses: PropTypes.instanceOf(List)
}

export default ItemsWithResponses
