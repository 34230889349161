import {connect} from 'react-redux'
import {Fragment, useEffect, useMemo, useState} from 'react'
import {Grid, useMediaQuery} from '@mui/material'
import {List, Map, OrderedMap} from 'immutable'
import {updateResource, readEndpoint} from 'redux-json-api'
import {useQuery} from '@apollo/client'
import PropTypes from 'prop-types'

import AboutTheScore from './AboutTheScore'
import CategoriesGrid from './categories_grid/CategoriesGrid'
import ContactCard from '../contact_card/ContactCard'
import Greetings from './Greetings'
import HelpfulResources from './helpful_resources/HelpfulResources'
import MobileAppAnnouncement from './mobile_app_announcement/MobileAppAnnouncement'
import NewbieProgramContainer from '../programs/newbie/NewbieProgramContainer'
import NextBestActions from '../../../../shared_components/next_best_actions/NextBestActions'
import ScoreAssessmentController from '../score_assessment/ScoreAssessmentController'
import ScoreController from './ScoreController'
import SharingModuleController from '../sharing_module/SharingModuleController'

import {activePlan} from '../../../../lib/planTools'
import {cleanGraphQLResponse} from '../../../../lib/graphQLTools'
import {cleanResponse} from './redux/apiHelper'
import {currentUserConfig, currentUser, userProfile, userPartner, userAssessment, userGapFillerContentDownload} from '../../../../lib/userTools'
import {nextBestActionsToBeRendered} from '../../lib/nextBestActionTools'
import {scrollToTopOfPage} from '../../../../lib/scrollTools'
import {showNewbieProgram} from '../../../../lib/programTools'
import {useEverplanData} from '../../../../lib/hooks'
import {USER_PROGRAMS} from '../../../../graphql/queries/userData'
import routerUtils from '../../../../lib/routerUtils'

import './dashboardController.scss'


const DashboardController = props => {
  const userConfig = props.userConfig
  const everplanId = userConfig.get('everplan-id')
  const context = 'dashboard'

  const [stopPollingScore, setStopPollingScore] = useState(false)

  const {itemResponses, newContacts} = useEverplanData(everplanId)
  const {data: userProgramsData} = useQuery(USER_PROGRAMS)
  const userProgram = cleanGraphQLResponse(userProgramsData, 'userPrograms').find(program => program.get('name') === 'newbie')

  const showProgram = useMemo(() => showNewbieProgram({userConfig, userProgram, itemResponses}), [userConfig, userProgram, itemResponses])
  const nextBestActions = useMemo(() => nextBestActionsToBeRendered({...props, itemResponses}), [itemResponses, props.assessment])
  const hasNotSeenAssessment = useMemo(() => !props.currentUser.getIn(['viewed-flags', 'has_seen_onboarding_assessment']), [])

  const isTabletLgOrAbove = useMediaQuery(theme => theme.breakpoints.up('tabletLg'))


  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  return (
    <div className='dashboard-controller'>
      <Greetings currentUser={props.currentUser} />
      <Grid container columnSpacing={{mobileSm: 0, tabletLg: 6}} direction='row-reverse'>
        <Grid className='right-column' item mobileSm={12} tabletLg={3.6}>
          {
            userConfig.get('score-enabled') &&
            <Fragment>
              <ScoreController userConfig={userConfig} stopPollingScore={stopPollingScore} />
              <AboutTheScore />
            </Fragment>
          }
          {
            userConfig.get('client') && isTabletLgOrAbove &&
            <ContactCard />
          }
          {
            isTabletLgOrAbove &&
            <SharingModuleController
              {...props}
              context={context}
              itemResponses={itemResponses}
              newContacts={newContacts}
            />
          }
        </Grid>
        <Grid className='left-column' item mobileSm={12} tabletLg={8.4}> {
          showProgram ?
            <NewbieProgramContainer {...props} setStopPollingScore={setStopPollingScore} context={context} itemResponses={itemResponses} userProgram={userProgram} /> :
            <NextBestActions
              {...props}
              nextBestActions={nextBestActions}
              itemResponses={itemResponses}
              newContacts={newContacts}
              context={context}
            />
        }
        <CategoriesGrid itemResponses={itemResponses} categories={props.categories} />
        {
          !isTabletLgOrAbove &&
            <SharingModuleController
              {...props}
              context={context}
              itemResponses={itemResponses}
              newContacts={newContacts}
            />}
        <HelpfulResources location={props.location} userConfig={userConfig} />
        {
          userConfig.get('client') && !isTabletLgOrAbove &&
            <ContactCard />
        }
        </Grid>
        <MobileAppAnnouncement {...props} />
        {
          hasNotSeenAssessment &&
          <ScoreAssessmentController
            currentUser={props.currentUser}
            itemResponses={itemResponses}
            readEndpoint={props.readEndpoint}
            updateResource={props.updateResource}
            userConfig={userConfig}
          />
        }
        {
          routerUtils.childrenWithProps(props.children, {
            context,
            ownEverplan: true,
            owners: props.owners,
            userConfig
          })
        }
      </Grid>
    </div>
  )
}

DashboardController.propTypes = {
  assessment: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  owners: PropTypes.instanceOf(List),
  responseGroups: PropTypes.instanceOf(OrderedMap),
  categories: PropTypes.instanceOf(List),
  currentUser: PropTypes.instanceOf(Map),
  plan: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const profile = userProfile({userConfig, profiles: state.api.profiles})

  return ({
    nextBestActions: cleanResponse(state.api['next-best-actions']),
    categories: state.categories,
    currentUser: currentUser({userConfig, users: state.api.users}),
    partner: userPartner({userConfig, partners: state.api.partners}),
    profile,
    assessment: userAssessment({userConfig, assessments: state.api.assessments}),
    owners: state.owners,
    userConfig,
    plan: activePlan(state.api.plans),
    gapFillerContentDownloads: userGapFillerContentDownload({profile, contentDownloads: cleanResponse(state.api['content-downloads'])})
  })
}

export default connect(mapStateToProps, {updateResource, readEndpoint})(DashboardController)
