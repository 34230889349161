import classnames from 'classnames'
import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {categorySlugByItem} from '../../lib/plan_data/category'
import {firstTopKitElementIdWithResponse, itemResponseGroupOwners} from '../../lib/plan_data/itemResponsesHelper'
import {viewItemPath} from '../../lib/urlTools'
import Logger from '../../lib/NewLogger'
import routerUtils from '../../lib/routerUtils'

import ManagePermissionsContainer from '../../dashboard/src/components/permissions/specific_item/ManagePermissionsContainer'
import ItemResponseGroupInformation from './ItemResponseGroupInformation'

import './itemResponseGroup.scss'


export default class ItemResponseGroup extends Component {
  constructor(props) {
    super(props)

    this.openViewModal = this.openViewModal.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    // account for adding a user to an HH on the pro-client page, since the responseGroups could be the same
    return nextProps.itemResponse.get('is-household') !== this.props.itemResponse.get('is-household') ||
    !nextProps.responseGroup.equals(this.props.responseGroup)
  }

  createViewedItem() {
    if (this.props.actor === 'deputy') {
      const payload = {
        type: 'viewed-items',
        attributes: {
          'user-id': this.props.userConfig.get('id'),
          'everplan-id': this.props.itemResponse.get('everplan-id'),
          'item-id': this.props.itemResponse.get('item-id'),
          data: {
            'group-id': [this.props.firstGroupId]
          }
        }
      }

      this.props.createResource(payload)
    }
  }

  logEvents() {
    const itemName = this.props.itemResponse.get('name')

    Logger.log({
      name: 'viewed_response_group',
      payload: {
        actor: this.props.actor,
        context: this.props.context,
        type: 'vault',
        everplan_id: this.props.itemResponse.get('everplan-id'),
        item: itemName,
        wildcard: itemName
      }
    })
  }

  openViewModal() {
    const itemResponse = this.props.itemResponse

    this.createViewedItem()
    this.logEvents()

    routerUtils.push(
      viewItemPath({
        groupId: this.props.firstGroupId,
        itemSlug: itemResponse.get('slug'),
        slug: categorySlugByItem({item: itemResponse, categories: this.props.categories}),
        path: this.props.path,
        elementId: firstTopKitElementIdWithResponse(this.props.responseGroup)
      })
    )
  }

  keyField() {
    const responseKeyField = this.props.responseGroup.get('key-field')

    return responseKeyField.isEmpty() ? 'Response' : responseKeyField
  }

  render() {
    if (this.props.responseGroup.isEmpty()) {
      return null
    } else {
      const keyField = this.keyField()
      const showManagePermissions = this.props.ownEverplan && this.props.context === 'category_page'

      return (
        <div className='response-group flex-container flex-child'>
          <div className='key-field-values-container'>
            <div
              className={
                classnames(
                  'response-keyfield flex-child flex-container',
                  {
                    'with-household':
                    this.props.itemResponse.get('is-household')
                  })
              }>
              <pre className={classnames({'has-file-response': this.props.responseGroup.get('has-attachment')})} onClick={this.openViewModal}>
                {keyField}
              </pre>
            </div>
            <div className='values-container'>
              {
                this.props.itemResponse.get('is-household') && (
                  <div className='value response-ownerships flex-child'>
                    {itemResponseGroupOwners(this.props.responseGroup)}
                  </div>
                )
              }
              <ItemResponseGroupInformation {...this.props} firstGroupId={this.props.firstGroupId} />
            </div>
          </div>
          {
            showManagePermissions && (
              <ManagePermissionsContainer
                {...this.props}
                firstGroupId={this.props.firstGroupId}
                keyField={keyField}
              />
            )
          }
        </div>
      )
    }
  }
}

ItemResponseGroup.defaultProps = {
  item: Map(),
  responseGroup: List()
}

ItemResponseGroup.propTypes = {
  actor: PropTypes.string,
  categories: PropTypes.instanceOf(List),
  context: PropTypes.string,
  createResource: PropTypes.func,
  itemResponse: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  onToggleManagePermissionsModal: PropTypes.func,
  ownEverplan: PropTypes.bool,
  path: PropTypes.string,
  responseGroup: PropTypes.instanceOf(Map),
  firstGroupId: PropTypes.string,
  userConfig: PropTypes.instanceOf(Map)
}
