import {Typography} from '@mui/material'

import './dashboardHeading.scss'

const DashboardHeading = ({children}) => (
  <div className='dashboard-heading'>
    <Typography variant='h5'>
      {children}
    </Typography>
  </div>
)

export default DashboardHeading

