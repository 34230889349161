import {Grid} from '@mui/material'
import {Map} from 'immutable'
import {useFormContext, Controller} from 'react-hook-form'
import PropTypes from 'prop-types'

import FilesDropzoneArea from './FilesDropzoneArea'

const Files = props => {
  const {control} = useFormContext()

  return (
    <Grid item xs={12}>
      <Controller
        name={`${props.name}.value`}
        control={control}
        defaultValue={[]}
        render={({field: {onChange}}) => <FilesDropzoneArea {...props} onChange={onChange} />}
      />
    </Grid>
  )
}


Files.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setIsUploadingFiles: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default Files
