import {useEffect} from 'react'
import PropTypes from 'prop-types'

import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'
import Button from '../../../../../shared_components/mui_base_components/button/Button'

import image from '../../../../../shared_assets/phone-send.svg'

import './deputyAddedConfirmation.scss'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

const DeputyAddedConfirmation = props => {
  useEffect(() => {
    MixpanelLogger.track('add_deputy_invite_sent', {context: props.context})
  }, [])

  return (
    <Modals.SuccessPopUpModal className='deputy-added-confirmation' showModalOnMount closerComponent={() => <Closer closer={props.exit} />}>
      <header>
        <img src={image} alt='Success image' />
        <h2>Your deputy invitation is on its way!</h2>
      </header>
      <div className='subheading'>
        We'll let you know when {props.firstName} accepts your invitation.
      </div>
      <div className='tip'>
        Tip: Give your deputy a heads up so the invite doesn't get lost in their inbox.
      </div>
      <Button onClick={props.exit}>Done</Button>
    </Modals.SuccessPopUpModal>
  )
}

DeputyAddedConfirmation.propTypes = {
  context: PropTypes.string,
  firstName: PropTypes.string,
  exit: PropTypes.func
}

export default DeputyAddedConfirmation
