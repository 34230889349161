import {Box, Typography} from '@mui/material'
import {Map} from 'immutable'
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/mui_base_components/button/Button.jsx'
import CircleContents from './CircleContents.jsx'
import LinkButton from '../../../../../shared_components/mui_base_components/link_button/LinkButton.jsx'

import {programImages} from '../../../../../lib/programTools.js'

import './contentScreen.scss'

const ContentScreen = ({handleLinkClick, handleNext, processing, screen, logMixpanelProgramEvent, eventName}) => {
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false)

  useEffect(() => {
    logMixpanelProgramEvent({name: eventName, payload: {screen: screen.get('event-name')}})
  }, [screen.get('id')])

  useEffect(() => {
    setAreButtonsDisabled(false)
  }, [screen])


  const handleClick = clickHandler => {
    setAreButtonsDisabled(true)
    clickHandler()
  }

  return (
    <Box className='content-screen'>
      {
        screen.get('points-text') &&
        <Typography className='points-text' align='center'>
          {screen.get('points-text')}
        </Typography>
      }
      <CircleContents contents={screen.get('circle-contents')} />
      {
        screen.get('circle-contents').size === 0 &&
        <img alt='' src={programImages[screen.get('image-name')]} />
      }
      <Typography className='heading' variant='h3' align='center'>
        {screen.get('heading')}
      </Typography>
      {
        screen.get('body-texts').map((bodyText, index) => (
          <Typography key={index} className='body-text'>
            {bodyText}
          </Typography>
        ))
      }
      <div className='buttons-container'>
        <Button
          disabled={areButtonsDisabled}
          loading={processing}
          onClick={() => handleClick(handleNext)}>
          {screen.get('button-text')}
        </Button>
        {
          screen.get('skip-text') &&
          <LinkButton
            disabled={areButtonsDisabled}
            loading={processing}
            onClick={() => handleClick(handleLinkClick)}>
            {screen.get('skip-text')}
          </LinkButton>
        }
      </div>
    </Box>
  )
}

ContentScreen.defaultProps = {
  eventName: 'view_program_screen'
}

ContentScreen.propTypes = {
  eventName: PropTypes.string,
  handleLinkClick: PropTypes.func,
  handleNext: PropTypes.func.isRequired,
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  screen: PropTypes.instanceOf(Map).isRequired
}

export default ContentScreen
