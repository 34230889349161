import {CircularProgress, styled} from '@mui/material'

import colors from '../../../dashboard/styles/abstracts/colors.module.scss'

const ButtonLoadingSpinner = styled(CircularProgress)(() => ({
  color: colors.buttonTextLight,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-10px',
  marginLeft: '-10px'
}))

export default ButtonLoadingSpinner
