import {Map} from 'immutable'
import {useState, useMemo} from 'react'
import PropTypes from 'prop-types'

import MilestoneScreen from './MilestoneScreen'

import {
  updateUserSectionsWithNextActiveSection,
  getActiveSectionName,
  getCurrentMilestoneScreen,
  buildUserProgramMutationVariables
} from '../../../../../lib/programTools'


const MilestoneContainer = props => {
  const currentScreen = useMemo(() => getCurrentMilestoneScreen(props), [])
  const [processing, setProcessing] = useState(false)

  const startProgram = () => {
    setProcessing(true)
    const {programConfig, program} = props
    const sectionName = programConfig[0].name

    props.createUserProgram({
      variables: {
        input: {
          name: program.get('name'),
          status: 'active',
          sections: [{
            name: sectionName,
            status: 'active',
            ...programConfig[0].extraAttributesOnStart
          }]
        }
      },
      onCompleted: () => {
        props.setCurrentSectionName(sectionName)
        setProcessing(false)
      }
    })
  }

  const activateNextSection = () => {
    const sections = updateUserSectionsWithNextActiveSection(props)

    if (sections) {
      props.updateUserProgram({
        variables: buildUserProgramMutationVariables({sections, userProgram: props.userProgram})
      })
    }
  }

  const onDoneClick = () => {
    props.upNextScreen ? props.setCurrentSectionName('up-next') : props.markProgramAsCompleted()
  }

  const startNextSection = () => props.setCurrentSectionName(getActiveSectionName(props.programConfig))

  const sharedProps = {
    screen: currentScreen,
    programConfig: props.programConfig,
    userProgram: props.userProgram,
    logMixpanelProgramEvent: props.logMixpanelProgramEvent
  }

  switch (currentScreen.get('milestone-screen-type')) {
    case 'intro':
      return <MilestoneScreen {...sharedProps} handleClick={startProgram} processing={processing} />
    case 'keep-going':
    case 'final-keep-going':
      return <MilestoneScreen {...sharedProps} handleMount={activateNextSection} handleClick={startNextSection} />
    case 'done':
      return <MilestoneScreen {...sharedProps} handleClick={onDoneClick} />
    default:
      break
  }
}

MilestoneContainer.propTypes = {
  createUserProgram: PropTypes.func,
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  markProgramAsCompleted: PropTypes.func,
  program: PropTypes.instanceOf(Map),
  programConfig: PropTypes.array,
  setCurrentSectionName: PropTypes.func,
  updateUserProgram: PropTypes.func,
  upNextScreen: PropTypes.instanceOf(Map),
  userProgram: PropTypes.instanceOf(Map)
}


export default MilestoneContainer
