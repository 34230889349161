/** @class WizardFieldContainer
 *
 * @desc
 * This container controls the flow of the fields screen which are render the elements, which are the questions asked for each wizard,
 * and use the `fieldStep` to figure out which element should be shown when clicking back and forth through the fields.
 */
import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Form from '../../../shared_components/forms/v2/Form'
import NextBestActionWizardHeader from './NextBestActionWizardHeader'
import Modals from '../../core/modals/Modals'
import WizardCloser from './WizardCloser'
import WizardField from './WizardField'

import {filterElementsByView, dependentResponsesFromView} from '../../../lib/viewTools'
import {rectifyConditionalMaps} from '../../../lib/plan_data/item'
import {buildItemResponseMutationVariables} from '../../../lib/plan_data/itemResponsesHelper'

import './wizardFieldContainer.scss'

export default class WizardFieldContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fieldStep: 1,
      wizardElements: props.wizardElements,
      element: props.wizardElements.first(),
      // These are the responses that will be submitted when a user exits or gets to the end
      responses: Map(),
      // If the first section is not the fields then the first field should have a back button
      showBackButton: props.section !== 'fields',
      processing: false,
      uploadingFiles: false,
      // These are all the responses a user has entered at any point so they can be shown when a user moves between sections
      allResponses: List()
    }

    this.closer = this.closer.bind(this)
    this.next = this.next.bind(this)
    this.back = this.back.bind(this)
    this.submitResponses = this.submitResponses.bind(this)
    this.updateFileUploadStatus = this.updateFileUploadStatus.bind(this)
  }

  closer() {
    return (
      <WizardCloser
        {...this.state}
        logEvent={this.props.logEvent}
        exit={this.props.toggleModal}
        submit={this.submitResponses}
      />
    )
  }

  submitResponses() {
    // using the method `this.responses` instead of responses in the state in-case the submit is triggered from the
    // closer and so want to include any added response on the current fieldStep as well.
    const responses = this.responses().toList()

    if (responses.isEmpty()) {
      this.props.toggleModal()
    } else {
      this.setState({processing: true})
      const formattedResponses = dependentResponsesFromView({
        groupId: this.props.groupId,
        view: this.props.view,
        responses
      })

      this.props.updateItemResponse({
        variables: buildItemResponseMutationVariables({
          itemResponse: this.props.itemResponse,
          ownershipIds: this.props.responsesOwnership,
          groupId: this.props.groupId,
          responses: formattedResponses
        }),
        onCompleted: () => {
          this.props.setNextSection('done')
          this.props.logNewResponseGroupWithNotification({itemResponse: this.props.itemResponse, isNextBestAction: true})
        }
      })
    }
  }

  /*
    Need to merge the response of the current fieldStep using the current element id, since the formData will include all elements that
    have at any point appeared in the wizard (so in the case of conditionals it can have outdated responses in there), and since
    the responses in the state are a map keyed on the response id from the formData the merge will update the value if the
    response already exists instead of adding a new one when a user moves back and forth in the wizard.

    After doing the merge we want to filter any responses that have no value, including any responses that a user previously added
    a value too and then removed.
  **/
  responses() {
    return this.state.responses
      .merge(this.currentElementData())
      .filter(response => response.get('value'))
  }

  logNextEvent() {
    this.props.logEvent({
      name: 'continued_on_wizard',
      payload: {
        element_id: this.state.element.get('id'),
        with_response: !!this.formData().find(data => data.get('element-id') === this.state.element.get('id') && data.get('value'))
      }
    })
  }

  currentElementData() {
    return this.formData().filter(data => data.get('element-id') === this.state.element.get('id'))
  }

  next() {
    this.refs.form.onSubmit()

    if (this.refs.form.isValid()) {
      this.logNextEvent()

      const wizardElements = this.updateWizardElements()
      // If it is the last element then we don't want to go to the next fieldStep, but submit the responses and go to the done screen
      // if there are any or just close the modal if there aren't
      if (wizardElements.size === this.state.fieldStep) {
        this.submitResponses()
      } else {
        this.setState({
          fieldStep: this.state.fieldStep + 1,
          // The wizardElements are a list and therefore the index is 0, so when we want to get the next element
          // the current fieldStep number can be used, since fieldSteps start at 1.
          element: wizardElements.get(this.state.fieldStep),
          wizardElements,
          responses: this.responses(),
          showBackButton: true // show the back button on next since it will always show on the next wizard screen when next is pressed
        })
      }
    }
  }

  logBackEvent() {
    this.props.logEvent({
      name: 'clicked_back_on_wizard',
      payload: {
        element_id: this.state.element.get('id')
      }
    })
  }

  resetError() {
    if (this.refs.form && !this.refs.form.isValid())
      this.refs.form.updateDataAndErrors(this.currentElementData().keySeq().get(0), Map(), List())
  }

  back() {
    this.logBackEvent()
    this.resetError()

    // Need to track any responses added so that they are shown if a user leaves the fields section and comes back,
    // since the form is taken of the dom and therefore is no longer tracking any added data.
    const allResponses = this.formData().toList()

    // If it is the first field and the previous button can be clicked (aka is rendered) then the
    // previous section (either the gather or ownership screen) needs to be set as the current section.
    if (this.state.fieldStep === 1) {
      this.setState({allResponses})
      this.props.setNextSection(this.props.previousSection)
    } else {
      const previousStep = this.state.fieldStep - 1
      this.setState({
        fieldStep: previousStep,
        // The wizardElements are a list and therefore the index is 0, so when we want to go to the previous element
        // the current fieldStep number minus 2 needs to be used, since fieldSteps start at 1.
        element: this.state.wizardElements.get(previousStep - 1),
        // When moving to a previous fieldStep the response of the current element needs to be removed
        // so that it is not saved if a user exits at the previous fieldStep.
        responses: this.state.responses.filterNot(responseData => responseData.get('element-id') === this.state.element.get('id')),
        allResponses,
        // Need to show the back button if the previous step is not the first field step or if the ownership screen comes before the first
        // field step otherwise hide it.
        showBackButton: this.props.previousSection || previousStep > 1
      })
    }
  }


  // Need to update the list of wizardElements to include conditional elements based on the responses added
  updateWizardElements() {
    const wizardElements = rectifyConditionalMaps({
      responses: this.formData(),
      parentElement: Map(),
      childElements: this.props.wizardElements,
      kits: this.props.kits,
      listMappings: this.props.listMappings
    })

    // `rectifyConditionalMaps` returns all wizardElements and in the case of conditionals we want to make sure
    // only wizardElements that are associated to the view are rendered.
    return filterElementsByView({elements: wizardElements, view: this.props.view})
  }

  formData() { return this.refs.form ? this.refs.form.formData() : List() }

  // Needed by the element component that is type file to indicate if any files are currently being uploaded
  updateFileUploadStatus(uploadingFiles) {
    if (uploadingFiles !== this.state.uploadingFiles)
      this.setState({uploadingFiles})
  }

  render() {
    return (
      <Modals.LinkPopUpModalLarge className='wizard-field-container' showModal={this.props.section === 'fields'} closerComponent={this.closer}>
        <Form className='core' ref='form' groupId={this.props.groupId}>
          <NextBestActionWizardHeader {...this.props} />
          <WizardField
            {...this.props}
            {...this.state}
            next={this.next}
            back={this.back}
            updateFileUploadStatus={this.updateFileUploadStatus}
            // The element component in wizard field uses this to render already added data as a person
            // moves back and forth in the sections.
            data={this.state.allResponses}
          />
        </Form>
      </Modals.LinkPopUpModalLarge>
    )
  }
}

WizardFieldContainer.propTypes = {
  groupId: PropTypes.array,
  itemResponse: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func,
  logNewResponseGroupWithNotification: PropTypes.func,
  nextBestAction: PropTypes.instanceOf(Map),
  previousSection: PropTypes.string,
  responsesOwnership: PropTypes.instanceOf(List),
  section: PropTypes.string,
  setNextSection: PropTypes.func,
  toggleModal: PropTypes.func,
  updateItemResponse: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  view: PropTypes.instanceOf(Map),
  views: PropTypes.instanceOf(List),
  wizardElements: PropTypes.instanceOf(List)
}
