import {IndexRoute, Redirect, Route} from 'react-router'

import '../../styles/main.scss' // PLEASE DO NOT MOVE! We need to import our reset and global styles rules BEFORE importing components --KW

import DashboardWrapper from '../components/DashboardWrapper'

// CORP MAX
import {addMore, newItem, editItem} from '../components/corp_max/item/ItemFormController'
import AddressBookContainer from '../components/corp_max/address_book/AddressBookContainer'
import EditAddressBookContact from '../components/corp_max/address_book/EditAddressBookContact'
import Category from '../components/corp_max/category/Category'
import CorpMaxController from '../components/corp_max/CorpMaxController'
import CompoundResponseGroup from '../components/corp_max/item/CompoundResponseGroup'
import ItemForm from '../components/corp_max/item/ItemForm'
import ItemController from '../components/corp_max/item/ItemController'
import ViewItem from '../components/corp_max/item/ViewItem'

import {MetaDataContainerWithUserConfigAndUser} from '../components/corp_max/everplan_preview/MetaDataContainer'
import EverplanPreview from '../components/corp_max/everplan_preview/EverplanPreview'

import DeputyForController from '../components/corp_max/deputy_for/DeputyForController'
import MyProfessionalPartnerContainer from '../components/settings/my_professional_partner/MyProfessionalPartnerContainer'

// CORP MAX DEPUTIES
import AddDeputyModal from '../components/corp_max_deputies/add/AddDeputyModal'
import DeputyViewingHistory from '../components/corp_max_deputies/DeputyViewingHistory'
import ManagePermissions from '../components/corp_max_deputies/permissions/ManagePermissions'
import ManageDeputyUnlockPermissionsController from '../components/corp_max_deputies/permissions/ManageDeputyUnlockPermissionsController'
import MyDeputiesController from '../components/corp_max_deputies/MyDeputiesController'
import RemoveDeputyController from '../components/corp_max_deputies/RemoveDeputyController'

// CORP MAX REDUX
import KeyFieldsToolController from '../components/corp_max/redux/key_fields_tool/KeyFieldsToolController'
import NextBestActionsToolController from '../components/corp_max/next_best_actions_tool/NextBestActionsToolController'

import ViewsToolController from '../components/corp_max/views_tool/ViewsToolController'
import VersionsToolController from '../components/corp_max/versions_tool/VersionsToolController'

import DashboardController from '../components/corp_max/DashboardController'

// SETTINGS ////////////////////////////////////////////////////////
import AccountController from '../components/AccountController'
import AccountSettingsRoutesRedirect from '../components/AccountSettingsRoutesRedirect'
import AccountSettings from '../components/corp_max/account_settings/AccountSettings'
import EmailPreferences from '../components/settings/EmailPreferences'
import TfaSetting from '../components/tfa/TfaSetting'
import DeleteAccountModal from '../components/corp_max/shared/DeleteAccountModal'

// OTHER ////////////////////////////////////////////////////////
import AuthGateway from '../../../shared_components/AuthGateway'
import CreditCardModalController from '../../../consumer/src/components/payment_page/CreditCardModalController'
import Container from '../../../lib/Container'
import newStorePrototype from '../../../shared_components/newStorePrototype'
import PaymentModal from '../../../consumer/src/components/payment_page/PaymentModal'
import PaymentReceivedTrialEnded from '../../../consumer/src/components/payment_page/PaymentReceivedTrialEnded'
import PaymentReceivedWithTrial from '../../../consumer/src/components/payment_page/PaymentReceivedWithTrial'
import PaymentReceivedWithCoupon from '../../../consumer/src/components/payment_page/PaymentRecievedWithCoupon'
import SessionApiResponse from '../../../auth/src/web/SessionApiResponse'
import UserActions from '../../../shared_actions/UserActions'
import PremiumAccessDenied from '../../../shared_components/PremiumAccessDenied'

// DEATH REPORT ////////////////////////////////////////////////////////
import CorpMaxHouseholdDeathReport from '../components/corp_max_deputies/death_report/CorpMaxHouseholdDeathReport'
import CorpMaxStepOne from '../components/corp_max_deputies/death_report/CorpMaxStepOne'
import CorpMaxStepTwo from '../components/corp_max_deputies/death_report/CorpMaxStepTwo'
import CorpMaxDeathReportModal from '../components/corp_max_deputies/death_report/CorpMaxDeathReportModal'

// FEEDBACK ////////////////////////////////////////////////////////
import LogoutFeedbackModal from '../components/LogoutFeedbackModal'

// ACCOUNT POPULATION
import AccountEntryMethodModal from '../components/corp_max/plaid/AccountEntryMethodModal'

// Digital Subscription Service
import DigitalSubscriptionLinkContainer from '../../../shared_components/next_best_actions/gap_fillers/gap_filler_assessments/digital_subscription/DigitalSubscriptionLinkContainer'

// NEXT BEST ACTIONS
import NextBestActionContainer from '../../../shared_components/next_best_actions/NextBestActionContainer'
import SubscriptionAndBillingContainer from '../components/settings/subscription_and_billing/SubscriptionAndBillingContainer'

import PendingRequiredActionCheck from '../../../consumer/src/components/PendingRequiredActionCheck'

Container.registerStore('currentUser', newStorePrototype([UserActions.Types.DID_UPDATE_USER, SessionApiResponse.Types.WEB_CURRENT_USER_RECEIVED]))

export default (
  <Route component={AuthGateway}>
    <Route component={MetaDataContainerWithUserConfigAndUser}>
      <Route component={PendingRequiredActionCheck}>
        <Route component={CorpMaxController}>
          <Route component={DashboardWrapper} >
            <Route component={LogoutFeedbackModal} path='logout-feedback' />
            <Route path='account-settings'>
              <IndexRoute component={AccountSettingsRoutesRedirect} />
              {/* Anything that ends up on this route should be redirected to it's corpmax counterpart */}
              <Route path='*' component={AccountSettingsRoutesRedirect} />
            </Route>
            <Redirect from='dashboard' to='cm/dashboard' />
            <Route path='cm'>
              <Route component={AccountController} path='account-information/:userId' />
              <Route component={TfaSetting} path='tfa-setting' />
              <Route path='account-settings'>
                <IndexRoute component={AccountSettings} />
                <Route component={EmailPreferences} path='email-preferences' />
                <Route component={SubscriptionAndBillingContainer} path='subscription-and-billing' />
                <Route component={MyProfessionalPartnerContainer} path='my-professional-partner' />
                <Route component={CreditCardModalController} path='credit-card-form' />
                {/* Added to avoid HTTP token errors - Used when user updates credit card */}
                <Route component={PaymentModal} path='payment-form' />
                {/* Added to avoid HTTP token errors - Used when user subscribes from account settings */}
                <Route path='payment-received'>
                  <Route component={PaymentReceivedTrialEnded} path='trial-ending' />
                  <Route component={PaymentReceivedWithTrial} path='trialing' />
                  <Route component={PaymentReceivedWithCoupon} path='coupon' />
                </Route>
                <Route component={DeleteAccountModal} path='delete-account' />
              </Route>
              <Route component={DashboardController} path='dashboard'>
                <Route component={AccountEntryMethodModal} path='account-entry/:itemSlug' />
                <Redirect from='category/remembering-me' to="category/after-i'm-gone" />
                <Redirect from='category/when-i-go' to="category/after-i'm-gone" />
                <Redirect from='category/old-age' to='category/aging' />
                <Redirect from='category/about-me' to={'category/personal-info-and-ids'} />
                <Redirect from='category/health-and-medical' to='category/health' />
                <Route path='category/:slug'>
                  <Route component={ItemController} path='item/:itemSlug'>
                    <Route component={newItem(ItemForm)} path='new(/:elementId)' />
                    <Route component={newItem(CompoundResponseGroup)} path='new/compound(/:elementId)(/:groupId)' />
                    <Route component={editItem(ItemForm)} path='edit(/:groupId)' />
                    <Route component={editItem(CompoundResponseGroup)} path='edit/compound(/:groupId)' />
                    <Route component={addMore(CompoundResponseGroup)} path='add-more/:id' />
                    <Route component={ViewItem} path='view(/:groupId)(/:elementId)' />
                  </Route>
                </Route>
              </Route>
              <Redirect from='my-everplan' to='dashboard' />
              <Redirect from='item/*' to='dashboard' />
              <Redirect from='category/remembering-me' to="category/after-i'm-gone" />
              <Redirect from='category/when-i-go' to="category/after-i'm-gone" />
              <Redirect from='category/old-age' to='category/aging' />
              <Redirect from='category/about-me' to={'category/personal-info-and-ids'} />
              <Redirect from='category/health-and-medical' to='category/health' />
              <Route component={Category} path='category/:slug'>
                <Route component={NextBestActionContainer} path='next-best-actions/:itemSlug' />
                <Route component={AccountEntryMethodModal} path='account-entry/:itemSlug' />
                <Route component={DigitalSubscriptionLinkContainer} path='digital-subscriptions' />
                <Route component={ItemController} path='item/:itemSlug'>
                  <Route component={newItem(ItemForm)} path='new(/:elementId)' />
                  <Route component={newItem(CompoundResponseGroup)} path='new/compound(/:elementId)(/:groupId)' />
                  <Route component={editItem(ItemForm)} path='edit(/:groupId)' />
                  <Route component={editItem(CompoundResponseGroup)} path='edit/compound(/:groupId)' />
                  <Route component={addMore(CompoundResponseGroup)} path='add-more/:id' />
                  <Route component={ViewItem} path='view(/:groupId)(/:elementId)' />
                </Route>
              </Route>
              <Route component={EverplanPreview} path='everplan-preview/:everplanId'>
                <Route path='category/:slug'>
                  <Route component={ItemController} path='item/:itemSlug'>
                    <Route component={ViewItem} path='view(/:groupId)(/:elementId)' />
                    <Route component={newItem(ItemForm)} path='new(/:elementId)' />
                    <Route component={newItem(CompoundResponseGroup)} path='new/compound(/:elementId)(/:groupId)' />
                    <Route component={editItem(ItemForm)} path='edit(/:groupId)' />
                    <Route component={editItem(CompoundResponseGroup)} path='edit/compound(/:groupId)' />
                    <Route component={addMore(CompoundResponseGroup)} path='add-more/:id' />
                  </Route>
                </Route>
              </Route>
              <Route component={KeyFieldsToolController} path='key-fields-tool' />
              <Route component={DeputyForController} path='deputy-for' />
              <Redirect from='deputies/others' to='deputy-for' />
              <Route component={AddressBookContainer} path='address-book'>
                <Route component={EditAddressBookContact} path=':contactId' />
              </Route>
              <Route component={NextBestActionsToolController} path='next-best-actions-tool' />
              <Route component={ViewsToolController} path='views-tool' />
              <Route component={VersionsToolController} path='versions-tool' />
              <Route path='deputies'>
                <Redirect from='assign' to='new' />
                <Redirect from='assign-advisor' to='new' state={{isAdvisor: true}} />
                <IndexRoute component={MyDeputiesController} />
                <Route component={MyDeputiesController}>
                  <Route component={DeputyViewingHistory} path=':deputyId/viewing-history' />
                  <Route component={AddDeputyModal} path='new(/:contactId)' />
                  <Route component={RemoveDeputyController} path=':deputyOwnershipId/remove' />
                  <Route component={ManagePermissions} path=':deputyOwnershipId/manage-permissions' />
                  <Route component={ManageDeputyUnlockPermissionsController} path=':deputyOwnershipId/manage-unlock-permissions' />
                </Route>
              </Route>
              <Route component={PremiumAccessDenied} path='premium-access-modal' />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route component={CorpMaxDeathReportModal} path='cm/report-death/:everplanId'>
        <Route component={CorpMaxHouseholdDeathReport} path='household' />
        <Route component={CorpMaxStepOne} path='1' />
        <Route component={CorpMaxStepTwo} path='2' />
      </Route>
    </Route>
  </Route>
)
