import {gql} from '@apollo/client'

import {SCORE_ASSESSMENT_FRAGMENT, SCREEN_ELEMENT_FRAGMENT, DEPENDENT_RESPONSE_ELEMENT_FRAGMENT} from '../fragments/cms'

export const CMS = gql`
  ${SCORE_ASSESSMENT_FRAGMENT}
  ${SCREEN_ELEMENT_FRAGMENT}
  ${DEPENDENT_RESPONSE_ELEMENT_FRAGMENT}
  query Cms {
    cms {
      scoreAssessment {...ScoreAssessmentFragment}
      newbieProgram {
        id
        name
        milestoneScreens {
          id
          buttonText
          heading
          milestoneScreenType
          subHeading
        }
        sections {
          id
          name
          scoreScreenText
          sectionType
          milestoneConfig {
            id
            heading
            image
            maxPoints
            subHeading
          }
          screens {
            id
            bodyTexts
            buttonText
            skipText
            eventName
            heading
            hideProgressBar
            imageName
            nextScreen
            pointsText
            screenType
            sortOrder
            hasItemOptions
            itemOptions {
              screenElements {...ScreenElementFragment}
              id
              heading
              subHeading
              label
              itemId
              viewSlug
              viewId
              dependentResponseElements {...DependentResponseElementFragment}
            }
            circleContents {
              id
              color
              text
            }
          }
        }
      }
    }
  }
`
