import _ from 'lodash'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import {Map, List} from 'immutable'
import {useEffect, useState, useMemo} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {useForm, FormProvider} from 'react-hook-form'
import {useMutation} from '@apollo/client'

import Button from '../../../../../shared_components/mui_base_components/button/Button.jsx'
import ElementField from '../../../../../shared_components/forms/v3/ElementField'
import LinkButton from '../../../../../shared_components/mui_base_components/link_button/LinkButton'

import {buildItemResponseMutationVariables} from '../../../../../lib/plan_data/itemResponsesHelper'
import {formatElementResponsesFromForm, allFormValuesEmpty} from '../../../../../shared_components/forms/v3/formTools'
import {UPDATE_ITEM_RESPONSE} from '../../../../../graphql/mutations/itemResponse'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger.js'

import './itemFormScreen.scss'

const ItemFormScreen = props => {
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)
  const [updateItemResponse] = useMutation(UPDATE_ITEM_RESPONSE)
  const {logNewResponseGroupWithoutNotification} = useScoreLedgerEventLogger(props.userConfig)

  const screen = props.screen
  const elements = screen.get('screen-elements')

  const defaultValues = elements.reduce((collection, element) =>
    collection.set(element.get('id'), element.mergeDeep(Map({id: uuid.v4()}))), Map()
  ).toJS()

  const methods = useForm({defaultValues})

  const formData = methods.watch()

  const submitDisabled = useMemo(() => (
    !_.isEmpty(methods.formState.errors) || isUploadingFiles || allFormValuesEmpty(formData)
  ), [formData, methods.formState, isUploadingFiles])

  const eventPayload = useMemo(() => {
    const {userSection} = props

    const viewedSlugsCount = userSection.get('view-slugs-added', List())
      .concat(userSection.get('view-slugs-skipped', List())).size + 1

    return ({screen: `${props.userSection.get('name')}_item${viewedSlugsCount}`, view_slug_name: screen.get('view-slug')})
  }, [props.userSection, props.screen])

  const itemResponse = useMemo(() => props.itemResponses.find(itemResponseData => itemResponseData.get('item-id') === screen.get('item-id')), [screen])

  const handleSubmit = () => {
    props.setProcessing(true)


    const buildResponses = {
      itemResponse,
      groupId: [uuid.v4(), uuid.v4()],
      responses: formatElementResponsesFromForm(formData).concat(screen.get('dependent-response-elements'))
    }

    updateItemResponse({
      variables: buildItemResponseMutationVariables(buildResponses),
      onCompleted: () => {
        logNewResponseGroupWithoutNotification({itemResponse, isNextBestAction: true})
        props.handleNext({viewSlugAdded: screen.get('view-slug')})
      }
    })
  }

  const handleSkip = () => {
    props.logMixpanelProgramEvent({name: 'skip_program_screen', payload: eventPayload})
    props.handleNext({viewSlugSkipped: screen.get('view-slug')})
  }

  useEffect(() => {
    props.logMixpanelProgramEvent({name: 'view_program_screen', payload: eventPayload})

    return () => {
      // resets the onFormChange to the default false when the screen unmounts
      props.onFormChange(false)
    }
  }, [])

  useEffect(() => {
    props.onFormChange(!allFormValuesEmpty(formData))
  }, [formData])


  return (
    <Box className='item-form-screen'>
      <Typography className='heading' variant='h3' align='center'>
        {screen.get('heading')}
      </Typography>
      <Typography className='subheading'>
        {screen.get('sub-heading')}
      </Typography>
      <FormProvider {...methods}>
        <form>
          <Stack className='form-fields-container' spacing={2}>
            {
              elements.map(element => (
                <ElementField
                  {...props}
                  viewId={screen.get('view-id')}
                  itemResponse={itemResponse}
                  key={element.get('id')}
                  element={element}
                  setIsUploadingFiles={setIsUploadingFiles}
                />
              ))
            }
          </Stack>
        </form>
        <div className='buttons-container'>
          <Button
            disabled={submitDisabled}
            loading={props.processing}
            onClick={methods.handleSubmit(handleSubmit)}
            type='submit'>
            Next
          </Button>
          <LinkButton
            disabled={props.processing}
            onClick={handleSkip}>
            Skip
          </LinkButton>
        </div>
      </FormProvider>
    </Box>
  )
}

ItemFormScreen.propTypes = {
  handleNext: PropTypes.func,
  itemResponses: PropTypes.instanceOf(List),
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  screen: PropTypes.instanceOf(Map),
  setProcessing: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  userSection: PropTypes.instanceOf(Map)
}

export default ItemFormScreen

