import {Typography, styled} from '@mui/material'

import colors from '../../../dashboard/styles/abstracts/colors.module.scss'

const StyledLinkText = styled(Typography)(() => ({
  color: colors.bodyText,
  fontSize: '0.9375rem',
  lineHeight: '113.333%',
  textDecorationLine: 'underline'
}))

export default StyledLinkText
