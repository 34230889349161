import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import {List, Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState, useMemo} from 'react'
import PropTypes from 'prop-types'

import AddDeputyStepOne from './AddDeputyStepOne'
import AddDeputyStepThree from './AddDeputyStepThree'
import AddDeputyStepTwo from './AddDeputyStepTwo'

import {CONTACTS} from '../../../../../graphql/queries/userData'
import {CREATE_DEPUTY} from '../../../../../graphql/mutations/deputy'
import {DEPUTIES, SPECIFIC_ITEMS_DEPUTIES} from '../../../../../graphql/queries/deputy'
import {filterRawResourceByAttributeId, findFirmConfigByFirmOwnership} from '../../../../../lib/plan_data/userData'
import {formateDeputyPermissions} from '../../../../../lib/deputyPermissionTools'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './addDeputyController.scss'

const AddDeputyController = props => {
  const [step, setStep] = useState(1)

  const everplanHasResponses = useMemo(() => props.itemResponses.some(itemResponse => !itemResponse.get('response-groups').isEmpty()), [])

  const logMixpanelEvent = eventName => MixpanelLogger.track(eventName, {context: props.context})

  const everplanId = props.userConfig.get('everplan-id')

  const [createDeputy] = useMutation(CREATE_DEPUTY, {
    update: (cache, {data}) => {
      const {deputies} = cache.readQuery({query: DEPUTIES, variables: {everplanId}})

      cache.writeQuery({
        query: DEPUTIES,
        data: {deputies: [...deputies, data.createDeputy]},
        variables: {everplanId}
      })
    },
    refetchQueries: [SPECIFIC_ITEMS_DEPUTIES, {query: CONTACTS, variables: {everplanId}}]
  })

  // This scrolls the modal to the top when moving in between the screens
  const scrollToTop = () => { document.getElementsByClassName('add-deputy-controller')[0].scrollTop = 0 }

  const goBack = event => {
    if (event)
      event.preventDefault()

    // Reset the permissions if they are going back from step 2 to step one
    if (step === 2)
      props.setDeputy({...props.deputy, permissions: null})

    setStep(step - 1)
    scrollToTop()
  }

  const saveAndGoToNextStep = (newDeputyData = {}) => {
    props.setDeputy({...props.deputy, ...newDeputyData})
    setStep(step + 1)
    scrollToTop()
  }

  const updateDeputy = (newDeputyData = {}) => { props.setDeputy({...props.deputy, ...newDeputyData}) }

  const sharedProps = {
    ...props,
    goBack,
    logMixpanelEvent,
    nextStep: saveAndGoToNextStep,
    updateDeputy
  }

  const submit = () => {
    const {deputy} = props

    const payload = {
      deathReporter: deputy['death-reporter'],
      deathVerificationPeriod: deputy['death-verification-period'],
      deputyRelationship: deputy['deputy-relationship'],
      deputyInvite: {
        firstName: deputy['first-name'],
        lastName: deputy['last-name'],
        email: deputy.email,
        customInviteText: deputy.inviteText
      },
      permissions: deputy.permissions ? formateDeputyPermissions(deputy.permissions) : []
    }

    createDeputy({
      variables: {input: payload},
      onCompleted: props.handleSubmitCompleted
    })
  }

  const renderStep = () => {
    if (step === 1)
      return <AddDeputyStepOne {...sharedProps} />
    // Need to go to step 3 directly if there are no responses
    else if (everplanHasResponses && step === 2)
      return <AddDeputyStepTwo {...sharedProps} everplanHasResponses={everplanHasResponses} />
    else
      return <AddDeputyStepThree {...sharedProps} submit={submit} />
  }


  return (
    <div className='add-deputy-controller'>{renderStep()}</div>
  )
}


AddDeputyController.propTypes = {
  context: PropTypes.string.isRequired,
  createResource: PropTypes.func,
  deputies: PropTypes.instanceOf(List),
  deputy: PropTypes.object,
  handleSubmitCompleted: PropTypes.func,
  itemResponses: PropTypes.instanceOf(List),
  setDeputy: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = (state, {userConfig}) => ({
  firmConfig: findFirmConfigByFirmOwnership({firmOwnerships: state.api['firm-ownerships'], firmConfigs: state.api['firm-configs'], userConfig}),
  itemViews: filterRawResourceByAttributeId({
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id'),
    rawResource: state.api['item-views']
  })
})


export default connect(mapStateToProps, {createResource})(AddDeputyController)
